<template>
  <section class="m-comment-submit m-scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="评价中心">
      <span class="header-slot" slot="right" @click="submitCommentHandle">提交</span>
    </m-header>

    <!-- 商品 -->
    <div class="goods-item" v-for="(item, index) in goodsList" :key="index">
      <div class="comment-item">
        <div class="item-content">
          <div class="content-left"><img :src="item.goods_sku_pic_url" alt="goods"></div>
          <div class="content-right">
            <p class="goods-title van-multi-ellipsis--l2">{{item.goods_name}}</p>
            <span>{{item.sku_name}} * {{item.goods_number}}</span>
          </div>
        </div>
        <div class="rate">
          <span class="rate-title">商品评分</span>
          <van-rate  v-model="item.rate" :size="23" color="#F03C18" void-icon="star" void-color="#ccc"/>
          <span class="rate-level">{{rateLevel(item.rate)}}</span>
        </div>
      </div>

      <div class="description">
        <img src="@/assets/comment/icon_bianji@2x.png" alt="" class="icon-edit">
        <textarea v-model="item.content" placeholder="从多个角度评价宝贝，可以帮助更多想买的人～"></textarea>

        <div class="upload-wrap">
          <!-- 上传视频或者图片 -->
          <van-uploader :after-read="(file) => uploadVideo(file, index)" accept="video/*" class="upload-video"  v-if="item.video_id == ''">
            <div class="video-box">
              <div v-if="!item.aloading">
                <img src="@/assets/comment/icon_shiping@2x.png" alt="">
                <span>上传视频</span>
              </div>
              <van-loading v-show="item.aloading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
            </div>
          </van-uploader>
          <div v-else class="poster-box">
            <img :src="item.poster"  alt="">
            <img src="@/assets/goods/play.png" alt="" class="play-video" @click="playVideo(item.video_url)">
            <i class="van-icon van-icon-clear van-uploader__preview-delete" @click.stop="videoDelete(index)"></i>
          </div>
          <van-uploader v-model="item.file_list" :after-read="(file) => uploadImg(file, index)" :upload-text="imgText(index)"
          :upload-icon="require('@/assets/comment/icon_tupian@2x.png')" :before-delete="(file) => delPicHandle(file, index)"
          max-count="5" preview-size="69px"></van-uploader>
        </div>
      </div>
    </div>

    <div class="rate-box">
      <div class="rate">
        <span class="rate-title">物流评分</span>
        <van-rate  v-model="w_rate" :size="23" color="#F03C18" void-icon="star" void-color="#ccc"/>
        <span class="rate-level">{{rateLevel(w_rate)}}</span>
      </div>
      <div class="rate">
        <span class="rate-title">服务评价</span>
        <van-rate  v-model="f_rate" :size="23" color="#F03C18" void-icon="star" void-color="#ccc"/>
        <span class="rate-level">{{rateLevel(f_rate)}}</span>
      </div>
    </div>
  
    <div class="recomnd">
      <img src="@/assets/comment/point.png" alt="">
      <span>为你推荐</span>
      <img src="@/assets/comment/point.png" alt="">
    </div>

    <goods-columns @cartClick="handleCart" :data="recomnd"></goods-columns>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>
    
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
      <CartFlex v-if="start" />
    </transition>
    <van-popup v-model="showVideo" position="left" :style="{width:'100%',height: '100%', background: '#000'}" closeable close-icon="close" @closed="onPlayerPause(player)">
        <video-player
          class="video-player vjs-custom-skin video"
          ref="videoPlayer"
          autoplay
          playsinline
          x5-playsinline
          webkit-playsinline
          :mtt-playsinline="true"
          :x5-video-player-type="h5" 
          x5-video-player-fullscreen
          :options="playerOptions"
          @pause="onPlayerPause($event)"
        ></video-player>
      </van-popup>
  </section>
</template>

<script>
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css';

import MHeader  from '@/components/zh/m-header.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import { getOrderRemGoodsList } from '@/api/en/order.js'
import { getOrderDetail, submitCommen } from '@/api/zh/order.js'
import { upload, delpic, videoUpload } from '@/api/public.js'
export default {
  name:'Index',
  components:{ MHeader, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      w_rate:5,
      f_rate: 5,
      skuModal:false,    // 控制显示 选择sku加入购物车面板
      loading:false,
      islock:false,     // 防止多次加载
      recomnd: [],        // 模拟为你推荐数据
      start:false,      // 是否开启加入购物车动画
      start_dom:null,   // 开启动画的起点DOM 
      goodsId: '',
      page: 1,
      goodsList: [],
      h5:'h5-page',
      playerOptions : {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: '375:375', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        poster: '', //你的封面地址
        sources: [{
          type: "video/mp4",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: '' //url地址
        }],
        width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '视频加载失败', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false, // 是否显示全屏按钮
          volumePanel: false, // 是否显示声音按钮
        },
      },
      showVideo: false,
      video: '',
      player: ''
    }
  },
  mounted(){
    this.getOrderDetailHandle()
    this.getRecommnd()
  },
  computed: {
    /* eslint-disable */
    rateLevel(rate){
      return (rate) => {
        if(rate == 1){
          return '非常糟糕'
        }
        else if(rate == 2 ){
          return '糟糕'
        }
        else if(rate == 3){
          return '一般'
        }
        else if(rate == 4){
          return '不错'
        }
        else if(rate == 5){
          return '完美'
        }else {
          return ''
        }
      }
    },
    imgText(index){
      return (index) => {
        return `${this.goodsList[index].pic_list.length} / 5`
      }
    }
  },
  methods:{
    // 组件滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getRecommnd()
        }
        this.islock = true
      }
    },
    // 上传视频
    uploadVideo(file, index) {
      this.goodsList[index].aloading = true
      var formData = new FormData()
      formData.append('filename', 'commentVideo')
      formData.append('file', file.file)
      videoUpload(formData).then(res => {
        if(res) {
          this.goodsList[index].video_id = res.data.video_id
          this.goodsList[index].poster = res.data.video_pic_url
          this.goodsList[index].video_url = res.data.remote_url
          file.video_id = res.data.video_id
        }
      }).finally(() => {
        this.goodsList[index].aloading = false
      })
    },
    // 点击视频预览图删除按钮
    videoDelete(index){
      this.goodsList[index].video_id = ''
      this.goodsList[index].video_url = ''
      this.goodsList[index].poster = ''
    },
    playVideo(src) {
      this.playerOptions.sources[0].src = src
      this.showVideo = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.play()
        this.player = this.$refs.videoPlayer.player
      })
    },
    // 暂停播放
    onPlayerPause(player) {
      player.pause()
    },
    uploadImg(file, index) {
      // 上传图片
      file.status = 'uploading'
      file.message = '上传中...'

      var image = new FormData()
      image.append('filename', 'comment')
      image.append('file', file.file)
      upload(image).then(res => {
        if(res){
          this.goodsList[index].pic_list.push(res.data.pic_id)
          file.pic_id = res.data.pic_id
        }
      }).finally(() => {
        file.status = 'done'
        file.message = ''
      })
    },
    delPicHandle(file, index) {
      if(file.pic_id != "") {
        
        delpic(file.pic_id).then( res => {
          if(res) {
            // 删除成功
            let i = this.goodsList[index].file_list.findIndex(v => v.pic_id == file.pic_id)
            this.goodsList[index].file_list.splice(i, 1)
            this.goodsList[index].pic_list.splice(i, 1)
          }
        })
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getRecommnd() {
      // 获取自定义为你推荐
      this.islock = true
      this.loading  = true
      getOrderRemGoodsList({page: this.page, goods_id: this.$route.query.id}).then(res => {
        this.loading  = false
        if(res) {
          this.recomnd = this.recomnd.concat(res.data.data)
          this.islock   = false
        }
      })
    },
    getOrderDetailHandle() {
      // 获取订单详情
      getOrderDetail({id: this.$route.query.id}).then(res => {
        if(res) {
          if(res.data.order_goods.length > 0) {
            res.data.order_goods.forEach(el => {
              el.rate      = 5
              el.video_id  = ''
              el.poster    = ''
              el.video_url    = ''
              el.pic_list  = []
              el.file_list = []
              el.content   = ''
              el.aloading = false
            })
            this.goodsList = res.data.order_goods
          }
        }
      })
    },
    submitCommentHandle() {
      // 提交评价
      let params = {
        w_comment: this.w_rate,
        f_comment: this.f_rate,
        order_id:  this.$route.query.id,
      }
      let list = []
      this.goodsList.forEach(el => {
        list.push({
          goods_id:  el.goods_id,
          content:   el.content,
          video_id:  el.video_id,
          pic_id:    el.pic_list.join(','),
          s_comment: el.rate,
        })
      })
      params.data = list
      console.log(params)
      submitCommen(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: '提交评论成功' })
          this.$router.go(-1)
        } else {
          this.$notify({ type: 'warning', message: '提交评论失败，请稍后再试' })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './submit.less';
</style>